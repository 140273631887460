
import {
  IonCol,
  IonGrid,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";
import { computed } from "@vue/reactivity";

import { Splitter } from "@/types/auxiliary";

import ListPlaceholder from "../ListPlaceholder.vue";

// NOTE: THIS COMPONENT RERENDERS AND RELOADS DATA UPON ANY SEARCH CHANGE
export default {
  name: "List",
  props: {
    splitters: Array,
    sizes: Object,
    items: Array,
    type: String,
  },
  setup(props: any) {
    const lists = computed(() => {
      if (props.items.length) {
        if (props.splitters) {
          return props.splitters
            .map((splitter: Splitter) => {
              return {
                name: splitter.name,
                items: props.items.filter(splitter.filter),
              };
            })
            .filter(
              (list: { name: string; items: Array<any> }) => list.items.length
            );
        }
        return [{ items: props.items }];
      }
      return [{ items: [] }];
    });

    return {
      lists,
    };
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItemDivider,
    IonItemGroup,
    ListPlaceholder,
  },
};
