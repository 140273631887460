
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "Currency Input",
  props: {
    modelValue: Number,
    options: Object,
    placeholder: String,
  },
  setup(props: any) {
    const { formattedValue, inputRef } = useCurrencyInput(props.options);

    return { inputRef, formattedValue };
  },
};
