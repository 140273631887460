
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from '@ionic/vue';
import { reactive, ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
import { add } from 'ionicons/icons';

import SearchToolbar from '@/components/inputs/SearchToolbar.vue';
import { createExpense } from '@/db';
import { formatDate } from '@/helpers';
import { Expense } from '@/types/units';

import ExpenseItem from './items/ExpenseItem.vue';
import List from './List.vue';

export default {
  name: 'Expenses Infinite',
  props: {
    title: String,
    modelValue: Array,
    date: String,
    employeeID: String,
    hideToolbar: Boolean,
  },
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive({
      allExpenses: props.modelValue,
      expenses: props.modelValue,
    });

    const filterUnpaid = ref(true);

    if (filterUnpaid.value === true) {
      state.expenses = state.allExpenses.filter(
        (expense: Expense) => expense.data.paid === false
      );
    } else {
      state.expenses = state.allExpenses;
    }

    const key = ref(0);

    watch(filterUnpaid, (status) => {
      if (status === true) {
        state.expenses = state.allExpenses.filter(
          (expense: Expense) => expense.data.paid === false
        );
      } else {
        state.expenses = state.allExpenses;
      }

      key.value += 1;
    });

    const addExpense = async () => {
      // Add expense to database & retrieve
      const expense = await createExpense(props.employeeID, {
        date: props.date ? props.date : formatDate(new Date().toISOString()),
      });
      state.expenses.unshift(expense);
      emit('update:modelValue', state.expenses);
    };

    const deleteExpense = async (expense: Expense) => {
      await expense.delete();
      state.expenses = state.expenses.filter(
        (_expense: Expense) => _expense.id != expense.id
      );
      emit('update:modelValue', state.expenses);
    };

    return {
      state,
      addExpense,
      deleteExpense,
      filterUnpaid,
      icons: { add },
      key,
    };
  },
  components: {
    ExpenseItem,
    SearchToolbar,
    List,
    IonIcon,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonRadioGroup,
    IonRadio,
    IonLabel,
    IonItem,
  },
};
