
import {
  IonButton,
  IonButtons,
  IonCard,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonToggle,
} from "@ionic/vue";
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { checkmark, trashOutline } from "ionicons/icons";

import CurrencyInput from "@/components/inputs/CurrencyInput.vue";
import store from "@/store";
import { Expense } from "@/types/units";

export default {
  name: "Expense",
  props: {
    expense: Object,
    showDate: Boolean,
    enableDelete: Boolean,
    disableInput: Boolean,
  },
  emits: ["deleteExpense", "costChange"],
  setup(props: any) {
    const state = reactive<{ expense: Expense }>({
      expense: props.expense,
    });

    watch(state.expense.data, () => state.expense.save());

    return {
      state,
      store,
      icons: {
        trashOutline,
        checkmark,
      },
    };
  },
  components: {
    IonItem,
    IonButtons,
    IonButton,
    IonNote,
    IonIcon,
    IonToggle,
    CurrencyInput,
    IonLabel,
    IonInput,
    IonCard,
  },
};
